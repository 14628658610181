// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-in-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.check-in-icon .check-in-icon__image {
  display: inline-block;
  position: relative;
}
.check-in-icon .check-in-icon__image img {
  background-size: contain;
  background-repeat: no-repeat;
}
.check-in-icon .check-in-icon__image > div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  mix-blend-mode: lighten;
}
.check-in-icon .check-in-icon__label {
  font-size: 0.85rem;
  font-weight: 500;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/check-ins-history/icon/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AADF;AAGE;EACE,qBAAA;EACA,kBAAA;AADJ;AAGI;EACE,wBAAA;EACA,4BAAA;AADN;AAII;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,uBAAA;AAFN;AAME;EACE,kBAAA;EACA,gBAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.check-in-icon {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n\n  .check-in-icon__image {\n    display: inline-block;\n    position: relative;\n\n    img {\n      background-size: contain;\n      background-repeat: no-repeat;\n    }\n\n    & > div {\n      position: absolute;\n      top: 0;\n      bottom: 0;\n      right: 0;\n      left: 0;\n      mix-blend-mode: lighten;\n    }\n  }\n\n  .check-in-icon__label {\n    font-size: 0.85rem;\n    font-weight: 500;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

/* eslint-disable react/no-did-update-set-state */
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';

export const DATASETS = {
  ACCOUNTS: 'Accounts',
  ADVISORS: 'Advisors',
  CLIENTS: 'Clients',
  EXCEPTIONS: 'Drift Exceptions',
  HOUSEHOLDS: 'Households',
  PROSPECTS: 'Prospects'
};

const defaultDatasets = Object.values(DATASETS).filter(l => l !== DATASETS.ADVISORS);

const useWidget = ({
  fetch,
  store,
  processor,
  datasets = defaultDatasets,
  companyModeRestricted = false,
  forceAdvisor = null
}) => {
  const { selectedTeam } = store;
  const defaultDataset = datasets.includes(DATASETS.CLIENTS) ? DATASETS.CLIENTS : datasets[0];

  const [dataset, setDataset] = useState(defaultDataset);
  const [restricted, setRestricted] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeDataset = newDataset => {
    setLoading(true);
    setDataset(newDataset);
  };

  const {
    accountProvider,
    advisorByManager,
    supervisorByManager,
    advisorProvider,
    authProvider,
    householdProvider,
    investorProvider,
    prospectProvider,
    surveillanceExceptionsProvider
  } = useContext(AdvisorContext);

  const getProvider = () => {
    let provider;
    switch (dataset) {
      case DATASETS.ACCOUNTS:
        provider = accountProvider;
        break;
      case DATASETS.ADVISORS:
        provider = advisorProvider;
        break;
      case DATASETS.HOUSEHOLDS:
        provider = householdProvider;
        break;
      case DATASETS.CLIENTS:
        provider = investorProvider;
        break;
      case DATASETS.PROSPECTS:
        provider = prospectProvider;
        break;
      case DATASETS.EXCEPTIONS:
        provider = surveillanceExceptionsProvider;
        break;
      default:
        return null;
    }
    return provider;
  };

  const getData = () => {
    let data;
    switch (dataset) {
      case DATASETS.ACCOUNTS:
        data = store.accounts;
        break;
      case DATASETS.ADVISORS:
        data = store.advisors;
        break;
      case DATASETS.HOUSEHOLDS:
        data = store.households;
        break;
      case DATASETS.CLIENTS:
        data = store.investors;
        break;
      case DATASETS.PROSPECTS:
        data = store.prospects;
        break;
      case DATASETS.EXCEPTIONS:
        data = store.exceptions;
        break;
      default:
        break;
    }
    return processor && data ? processor(data, dataset) : data;
  };

  /* fetchData
   * Handle the change of dataset or user.
   *
   * There are 3 scenarios:
   *
   * - If the widget has company-restriction and the advisor changes from "All"
   * to a particular advisor, disable the restriction (dataset=default).
   *
   * - If the widget has company-restriction and the advisor changes to "All",
   * activate the restriction (dataset=Advisor).
   *
   * - If the dataset change or an specific advisor is selected, fetch the
   * corresponding data.
   */
  async function fetchData(params = {}) {
    setLoading(true);
    const advisor = authProvider.hasCompliancePermissionsOrAbove(store.user)
      ? forceAdvisor ?? advisorByManager
      : undefined;
    const supervisor = authProvider.hasCompliancePermissionsOrAbove(store.user)
      ? forceAdvisor ?? supervisorByManager
      : undefined;
    // Scenario 1: Restrict the company dataset view.
    // do not fetch here. A new effect is about to be resolved with the new dataset.
    if (companyModeRestricted && !restricted && advisor === 0) {
      setRestricted(true);
      setDataset(DATASETS.ADVISORS);
      return;
    }

    // Scenario 2: Release the company-dataset restriction.
    // do not fetch here. A new effect is about to be resolved with the new dataset.
    if (companyModeRestricted && restricted && advisor !== 0) {
      // do not fetch here. A new effect is about to be resolved with the new dataset.
      setRestricted(false);
      setDataset(defaultDataset);
      return;
    }

    // Base case: The dataset or the advisor changed. Fetch the requested data.
    const provider = getProvider();
    if (advisor) params.by_advisor = advisor;
    if (supervisor) params.by_supervisor = supervisor;
    if (selectedTeam) params.by_team = selectedTeam;
    await fetch(provider, params);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [dataset, store.user, forceAdvisor, advisorByManager, supervisorByManager, selectedTeam]);

  const data = useMemo(
    () => getData(),
    [dataset, store.accounts, store.advisors, store.households, store.investors, store.prospects]
  );
  const empty = _.isEmpty(data);

  return {
    dataset,
    datasets,
    setDataset: changeDataset,
    loading,
    data,
    empty,
    fetchData,
    restricted
  };
};

export const widgetStoreSelector = accesor => state => {
  const accounts = state.accounts[accesor];
  const advisors = state.advisors[accesor];
  const exceptions = state.surveillanceExceptions[accesor];
  const households = state.households[accesor];
  const investors = state.investors[accesor];
  const prospects = state.prospects[accesor];
  const selectedTeam = state.teams.selected.value;
  const { user } = state.auth;

  return {
    accounts,
    advisors,
    exceptions,
    households,
    investors,
    prospects,
    selectedTeam,
    user
  };
};

export const widgetStorePropTypes = {
  accounts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  advisors: PropTypes.array,
  clients: PropTypes.object,
  households: PropTypes.object,
  prospects: PropTypes.object,
  user: PropTypes.object.isRequired
};

export default useWidget;

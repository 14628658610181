export const AUTH_LOGIN = 'auth-login';
export const AUTH_LOGOUT = 'auth-logout';
export const AUTH_SET_USER = 'auth-set-user';
export const AUTH_SET_COMPANY_LOGO = 'auth-set-company-logo';

export const SET_ADVISOR_BY_MANAGER = 'set-advisor-by-manager';
export const SET_SUPERVISOR_BY_MANAGER = 'set-supervisor-by-manager';

export const USER_UPDATE = 'user-update';

export const USER_LIST = 'user-list';

export const PERMISSION_STAFF = 'staff';

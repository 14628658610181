/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import AdvisorFilter from 'components/advisor/advisor-filter';
import SupervisorFilter from 'components/advisor/supervisor-filter';
import { ALL_ADVISORS_DEFAULT_VALUE as ALL_ADVISORS } from 'components/advisor/advisor-filter/constants';
import TeamFilter from 'components/advisor/team-filter';
import { DEFAULT_TEAM_OPTION as ALL_TEAMS } from 'components/advisor/team-filter/constants';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import FilterContainer from '../filters/common/filter-container';
import {
  ADVISOR_ATTR,
  MODEL_ADVISOR_ATTR,
  TEAM_ATTR,
  SUPERVISOR_ATTR,
  MODEL_SUPERVISOR_ATTR
} from '../filters/constants';
import { getActiveFilters, getActiveFiltersCount } from '../filters/utils';
import './styles.scss';

const DynamicTableFiltersModal = ({
  advisorByManager,
  filterComponents,
  initialParams,
  label,
  loading,
  meta,
  onChangeFilters,
  selectedTeam,
  supervisorByManager,
  table,
  withAdvisorFilter,
  withTeamFilter
}) => {
  const { authProvider, teamProvider, user } = useContext(AdvisorContext);

  const [filters, setFilters] = useState({ ...initialParams, ...(meta?.params || {}) });
  const [isShown, setIsShown] = useState(false);
  const [requiresPageIndexReset, setRequiresPageIndexReset] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [clearing, setClearing] = useState(false);

  const isModelPortfolioView = window.location.pathname.includes('/advisor/models');

  const userIsComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);
  const activeFilters = getActiveFilters(filters);
  const activeFiltersCount = getActiveFiltersCount(filters);
  const advisorAttr = isModelPortfolioView ? MODEL_ADVISOR_ATTR : ADVISOR_ATTR;
  const supervisorAttr = isModelPortfolioView ? MODEL_SUPERVISOR_ATTR : SUPERVISOR_ATTR;
  const hasGroups = filterComponents.length > 1;
  const modalSizeClassName = hasGroups ? 'modal-xl' : 'modal-md';
  const modalLabelClassName = label.toLowerCase().replace(/ /g, '-');

  useEffect(() => {
    if (meta?.params && !_.isEmpty(meta.params))
      setFilters(prevFilters => ({ ...prevFilters, ...meta.params }));
  }, [JSON.stringify(meta?.params)]);

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const onAdvisorChangeHandler = advisorId => {
    setFilters(prevFilters => ({ ...prevFilters, [advisorAttr]: advisorId || undefined }));
    setRequiresPageIndexReset(true);
  };

  const onSupervisorChangeHandler = advisorId => {
    setFilters(prevFilters => ({ ...prevFilters, [supervisorAttr]: advisorId || undefined }));
    setRequiresPageIndexReset(true);
  };

  const onTeamChangeHandler = teamId => {
    setFilters(prevFilters => ({ ...prevFilters, [TEAM_ATTR]: teamId || undefined }));
    setRequiresPageIndexReset(true);
  };

  const onChangeFiltersHandler = async () => {
    setSubmitting(true);
    if (requiresPageIndexReset) {
      table.setPageIndex(0);
      filters.page = 1;
    }
    await onChangeFilters(filters)
      .then(() => {
        setRequiresPageIndexReset(false);
        hide();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onClearFiltersHandler = async () => {
    setClearing(true);
    table.setPageIndex(0);
    const filtersToReset = activeFilters.reduce((acc, key) => ({ ...acc, [key]: undefined }), {});
    const updatedFilters = { ...filters, ...filtersToReset, page: 1 };
    setFilters(updatedFilters);
    await onChangeFilters(updatedFilters)
      .then(() => {
        authProvider.setAdvisorByManager(ALL_ADVISORS);
        authProvider.setSupervisorByManager(ALL_ADVISORS);
        teamProvider.setSelectedTeam(ALL_TEAMS);
        setRequiresPageIndexReset(false);
        hide();
      })
      .finally(() => {
        setClearing(false);
      });
  };

  return (
    <>
      <div className="btn-custom-filters-container">
        <button
          className="btn btn-outline-dark btn-custom-filters"
          disabled={loading}
          onClick={show}
          type="button"
        >
          <i className="icon-equalizer" />
          Filters
        </button>
        {!!activeFiltersCount && (
          <span className="filters-badge badge badge-dark">{activeFiltersCount}</span>
        )}
      </div>

      {ReactDOM.createPortal(
        <Modal
          className={cn(modalSizeClassName, modalLabelClassName)}
          id="sdt__filters-modal"
          onHidden={hide}
          show={isShown}
        >
          <ModalHeader />
          <ModalBody>
            <h3 className="modal-title">Filters</h3>
            <div className="filters-modal__base mt-2">
              {filterComponents.map((group, gIdx) => (
                <div
                  key={`filter-group-${gIdx}`}
                  className={cn('filter-group', { 'filter-group--wide': gIdx === 0 && hasGroups })}
                >
                  {gIdx === 0 && (
                    <>
                      {withAdvisorFilter && (
                        <FilterContainer
                          attrs={[ADVISOR_ATTR, MODEL_ADVISOR_ATTR]}
                          filters={filters}
                          label="Advisor"
                        >
                          <AdvisorFilter
                            className="white-background select-options__advisor"
                            isDisabled={supervisorByManager !== 0 || selectedTeam !== 0}
                            onChange={onAdvisorChangeHandler}
                          />
                        </FilterContainer>
                      )}
                      {withAdvisorFilter && userIsComplianceOrAbove && (
                        <FilterContainer
                          attrs={[SUPERVISOR_ATTR, MODEL_SUPERVISOR_ATTR]}
                          filters={filters}
                          label="Supervisor"
                        >
                          <SupervisorFilter
                            className="white-background select-options__advisor"
                            isDisabled={selectedTeam !== 0 || advisorByManager !== 0}
                            onChange={onSupervisorChangeHandler}
                          />
                        </FilterContainer>
                      )}
                      {withTeamFilter && (
                        <FilterContainer attrs={[TEAM_ATTR]} filters={filters} label="Team">
                          <TeamFilter
                            isDisabled={supervisorByManager !== 0 || advisorByManager !== 0}
                            onChange={onTeamChangeHandler}
                          />
                        </FilterContainer>
                      )}
                    </>
                  )}
                  {group.map((Component, cIdx) => (
                    <Component
                      filters={filters}
                      key={`fc-${gIdx}-${cIdx}`}
                      setFilters={setFilters}
                      setRequiresPageIndexReset={setRequiresPageIndexReset}
                    />
                  ))}
                </div>
              ))}
            </div>
            <div className="filters-modal__actions">
              <button
                type="button"
                className="btn btn-link text-dark font-weight-normal"
                disabled={clearing || submitting}
                onClick={onClearFiltersHandler}
              >
                {clearing ? 'Clearing...' : 'Clear all'}
              </button>
              <LoadingButton
                className="btn btn-primary"
                disabled={clearing || submitting}
                loading={submitting}
                onClick={onChangeFiltersHandler}
              >
                Apply filters
              </LoadingButton>
            </div>
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

DynamicTableFiltersModal.propTypes = {
  advisorByManager: PropTypes.number.isRequired,
  filterComponents: PropTypes.array,
  initialParams: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  selectedTeam: PropTypes.number.isRequired,
  supervisorByManager: PropTypes.number.isRequired,
  table: PropTypes.object.isRequired,
  withAdvisorFilter: PropTypes.bool.isRequired,
  withTeamFilter: PropTypes.bool.isRequired
};

DynamicTableFiltersModal.defaultProps = {
  filterComponents: [],
  loading: false
};

export default connect(state => ({
  advisorByManager: state.auth.advisorByManager,
  selectedTeam: state.teams.selected.value,
  supervisorByManager: state.auth.supervisorByManager
}))(DynamicTableFiltersModal);

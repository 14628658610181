import EmailReminders from 'app/components/email-reminder';
import { VerboseErrorInput } from 'app/components/form';
import cn from 'classnames';
import { RequestAction } from 'components/advisor/section-locked';
import Disclosure from 'components/disclosure';
import Choice from 'components/form/choice';
import Radio from 'components/form/radio';
import DriftRangeSlider from 'components/slider/drift-range';
import RiskLevelsConfiguration, {
  validateRiskLevels
} from 'app/components/risk-levels/configuration';
import { AdvisorContext } from 'containers/advisor';
import NewTag from 'containers/advisor/new-tag';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import { RISK_LEVELS } from 'containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import React, { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import ConfigurationFormSection from './form-section';
import './styles.scss';

const DRIFT_RANGE_SLIDER_STYLE = { display: 'none' };

const floatToDecimal = value => (Number.isFinite(value) ? parseFloat(value.toFixed(2)) : '');

const decimalToPercent = value => (Number.isFinite(value) ? value * 100 : '');

const percentToDecimal = value =>
  Number.isFinite(value) ? parseFloat((value / 100).toFixed(4)) : '';

const validate = values => {
  const errors = {};

  errors.risk_levels = validateRiskLevels(values.risk_levels);

  return errors;
};

const ThresholdValueInput = ({ field, onChangeInput }) => (
  <VerboseErrorInput
    {...field.initialValue}
    className="form-control input-labe"
    label="Minimum portfolio value monitored"
  >
    <NumericFormat
      allowNegative={false}
      className="form-control"
      decimalScale={0}
      onValueChange={onChangeInput(field)}
      prefix="$"
      thousandSeparator
      value={field.value}
    />
  </VerboseErrorInput>
);

const Configuration = ({ fields, initializeForm, registerError }) => {
  const {
    authProvider,
    companyProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const [lastSavedValues, setLastSavedValues] = useState(null);

  useEffect(() => {
    const values = {
      account_value_threshold: company.account_value_threshold,
      allow_advisors_copy_risk_tolerance_link: company.allow_advisors_copy_risk_tolerance_link,
      allow_advisors_creating_benchmarks: company.allow_advisors_creating_benchmarks,
      allow_advisors_creating_templates: company.allow_advisors_creating_templates,
      allow_advisors_custom_securities_access: company.allow_advisors_custom_securities_access,
      allow_advisors_excluding_positions: company.allow_advisors_excluding_positions,
      allow_advisors_integrations_access: company.allow_advisors_integrations_access,
      allow_advisors_models_marketplace_access: company.allow_advisors_models_marketplace_access,
      allow_advisors_scan_iq_access: company.allow_advisors_scan_iq_access,
      allow_advisors_sharing_models: company.allow_advisors_sharing_models,
      allow_advisors_update_target_score_manually:
        company.allow_advisors_update_target_score_manually,
      allow_programmatic_target_score_override: company.allow_programmatic_target_score_override,
      checkins_automatic_delivery: company.checkins_automatic_delivery,
      checkins_available: company.checkins_available,
      checkins_enabled: company.checkins_enabled,
      checkins_frequency: company.checkins_frequency,
      default_benchmark_management_fee: decimalToPercent(company.default_benchmark_management_fee),
      default_recommended_management_fee: decimalToPercent(
        company.default_recommended_management_fee
      ),
      default_target_management_fee: decimalToPercent(company.default_target_management_fee),
      drift_monitoring_accounts_enabled: company.drift_monitoring_accounts_enabled,
      drift_monitoring_households_enabled: company.drift_monitoring_households_enabled,
      drift_monitoring_investors_enabled: company.drift_monitoring_investors_enabled,
      drift_threshold_above: company.drift_threshold_above,
      drift_threshold_below: company.drift_threshold_below,
      email_reminder_offsets: company.email_reminder_offsets,
      enforce_second_factor: company.enforce_second_factor,
      exceptions_emails_enabled: company.exceptions_emails_enabled,
      exceptions_enabled: company.exceptions_enabled,
      exceptions_for_high_risk: company.exceptions_for_high_risk,
      exceptions_for_high_tolerance: company.exceptions_for_high_tolerance,
      exceptions_summary_emails_enabled: company.exceptions_summary_emails_enabled,
      household_value_threshold: company.household_value_threshold,
      investor_value_threshold: company.investor_value_threshold,
      market_downside_performance: company.market_downside_performance,
      mask_account_number: company.mask_account_number,
      reminders_enabled: company.reminders_enabled, // Pca07
      risk_levels: company.risk_levels || RISK_LEVELS,
      show_built_in_questionnaire_templates: company.show_built_in_questionnaire_templates
    };
    setLastSavedValues(values);
    initializeForm(values);
  }, []);

  const onSubmit = async (values, message) => {
    try {
      const response = await companyProvider.update(values);
      if (response.error) registerError(response);
      else {
        toast.success(message);
        authProvider.getUser();
      }
    } catch (error) {
      // Handle potential errors that may occur during the update process
      registerError(error);
    }
  };

  const cancelRiskLevels = () => {
    if (lastSavedValues) fields.risk_levels.onChange(lastSavedValues.risk_levels);
  };

  const resetRiskLevelsToDefault = () => {
    fields.risk_levels.onChange(RISK_LEVELS);
  };

  const onAdvisorPermissionsSubmit = e => {
    e.preventDefault();
    const values = {
      allow_advisors_copy_risk_tolerance_link: fields.allow_advisors_copy_risk_tolerance_link.value,
      allow_advisors_creating_templates: fields.allow_advisors_creating_templates.value,
      allow_advisors_custom_securities_access: fields.allow_advisors_custom_securities_access.value,
      allow_advisors_excluding_positions: fields.allow_advisors_excluding_positions.value,
      allow_advisors_integrations_access: fields.allow_advisors_integrations_access.value,
      allow_advisors_models_marketplace_access:
        fields.allow_advisors_models_marketplace_access.value,
      allow_advisors_scan_iq_access: fields.allow_advisors_scan_iq_access.value,
      allow_advisors_sharing_models: fields.allow_advisors_sharing_models.value,
      allow_advisors_update_target_score_manually:
        fields.allow_advisors_update_target_score_manually.value,
      allow_advisors_creating_benchmarks: fields.allow_advisors_creating_benchmarks.value
    };
    onSubmit(values, 'Advisor permissions saved successfully');
  };

  const onDriftExceptionsSubmit = e => {
    e.preventDefault();
    const values = {
      account_value_threshold: fields.account_value_threshold.value,
      drift_monitoring_accounts_enabled: fields.drift_monitoring_accounts_enabled.value,
      drift_monitoring_households_enabled: fields.drift_monitoring_households_enabled.value,
      drift_monitoring_investors_enabled: fields.drift_monitoring_investors_enabled.value,
      drift_threshold_above: fields.drift_threshold_above.value,
      drift_threshold_below: fields.drift_threshold_below.value,
      exceptions_emails_enabled: fields.exceptions_emails_enabled.value,
      exceptions_enabled: fields.exceptions_enabled.value,
      exceptions_for_high_risk: fields.exceptions_for_high_risk.value,
      exceptions_for_high_tolerance: fields.exceptions_for_high_tolerance.value,
      exceptions_summary_emails_enabled: fields.exceptions_summary_emails_enabled.value,
      household_value_threshold: fields.household_value_threshold.value,
      investor_value_threshold: fields.investor_value_threshold.value
    };
    onSubmit(values, 'Drift exceptions saved successfully');
  };

  const onRiskToleranceQuestionnairesSubmit = e => {
    e.preventDefault();
    const values = {
      show_built_in_questionnaire_templates: fields.show_built_in_questionnaire_templates.value,
      allow_programmatic_target_score_override:
        fields.allow_programmatic_target_score_override.value
    };
    onSubmit(values, 'Risk tolerance questionnaires saved successfully');
  };

  const onProposalsSubmit = e => {
    e.preventDefault();
    const values = {
      default_benchmark_management_fee: percentToDecimal(
        fields.default_benchmark_management_fee.value
      ),
      default_recommended_management_fee: percentToDecimal(
        fields.default_recommended_management_fee.value
      ),
      default_target_management_fee: percentToDecimal(fields.default_target_management_fee.value)
    };
    onSubmit(values, 'Proposals saved successfully');
  };

  const onRiskLevelsSubmit = e => {
    e.preventDefault();
    const values = {
      risk_levels: fields.risk_levels.value
    };
    onSubmit(values, 'Risk levels saved successfully');
    setLastSavedValues(values);
  };

  const onSecuritySubmit = e => {
    e.preventDefault();
    const values = {
      enforce_second_factor: fields.enforce_second_factor.value,
      mask_account_number: fields.mask_account_number.value
    };
    onSubmit(values, 'Security saved successfully');
  };

  const onEmailRemindersSubmit = e => {
    e.preventDefault();
    const values = {
      email_reminder_offsets: fields.email_reminder_offsets.value,
      reminders_enabled: fields.reminders_enabled.value
    };
    onSubmit(values, 'Email reminders saved successfully');
  };

  const onCheckinSubmit = e => {
    e.preventDefault();
    const values = {
      checkins_automatic_delivery: fields.checkins_automatic_delivery.value,
      checkins_enabled: fields.checkins_enabled.value,
      checkins_frequency: fields.checkins_frequency.value
    };
    onSubmit(values, 'Check-in saved successfully');
  };

  const handleChange = (field, value) => {
    fields[field].onChange(value);
  };

  const onChangeChoice = field => () => {
    handleChange(field.name, !field.value);
  };

  const onChangeSlider = field => values => {
    handleChange(field.name, +values);
  };

  const onChangeInput =
    field =>
    ({ floatValue }) => {
      handleChange(field.name, floatValue);
    };

  const generateInputConfig = (field, onChangeInput) => ({
    className: 'form-control',
    allowNegative: false,
    decimalScale: 2,
    onValueChange: onChangeInput(field),
    suffix: '%',
    value: floatToDecimal(field.value),
    placeholder: 'Value in percentage, e.g, 1%'
  });

  const defaultTargetManagementFeeInput = generateInputConfig(
    fields.default_target_management_fee,
    onChangeInput
  );

  const defaultBenchmarkManagementFeeInput = generateInputConfig(
    fields.default_benchmark_management_fee,
    onChangeInput
  );

  const defaultRecommendedManagementFeeInput = generateInputConfig(
    fields.default_recommended_management_fee,
    onChangeInput
  );

  const handleDriftMonitoringChange = selectedField => () => {
    // list of all related radio fields
    const radioFields = [
      'drift_monitoring_accounts_enabled',
      'drift_monitoring_investors_enabled',
      'drift_monitoring_households_enabled'
    ];
    // update each field: set selected field to true and others to false
    radioFields.forEach(field => {
      fields[field].onChange(field === selectedField);
    });
  };

  const handleSaveEmailReminders = newOffsets => {
    handleChange('email_reminder_offsets', newOffsets);
  };

  return (
    <div id="configuration">
      <h1>Advisor Permissions</h1>
      <div className="content">
        <ConfigurationFormSection onSubmit={onAdvisorPermissionsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.allow_advisors_integrations_access}
                title="Access integrations setup"
                toggle={onChangeChoice(fields.allow_advisors_integrations_access)}
              />
              <Choice
                {...fields.allow_advisors_models_marketplace_access}
                disabled={!company.models_marketplace_enabled}
                title="Access Market IQ"
                toggle={onChangeChoice(fields.allow_advisors_models_marketplace_access)}
              />
              <Choice
                {...fields.allow_advisors_scan_iq_access}
                disabled={!company.models_marketplace_enabled}
                title="Access Scan IQ"
                toggle={onChangeChoice(fields.allow_advisors_scan_iq_access)}
              />
              <Choice
                {...fields.allow_advisors_custom_securities_access}
                disabled={!company.models_marketplace_enabled}
                title="Manage Custom Security"
                toggle={onChangeChoice(fields.allow_advisors_custom_securities_access)}
              />
              <Choice
                {...fields.allow_advisors_update_target_score_manually}
                title="Set Risk tolerance manually"
                toggle={onChangeChoice(fields.allow_advisors_update_target_score_manually)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.allow_advisors_creating_benchmarks}
                title="Create benchmarks"
                toggle={onChangeChoice(fields.allow_advisors_creating_benchmarks)}
              />
              <Choice
                {...fields.allow_advisors_sharing_models}
                title="Share models"
                toggle={onChangeChoice(fields.allow_advisors_sharing_models)}
              />
              <Choice
                {...fields.allow_advisors_creating_templates}
                title="Create templates"
                toggle={onChangeChoice(fields.allow_advisors_creating_templates)}
              />
              <Choice
                {...fields.allow_advisors_excluding_positions}
                title="Exclude positions"
                toggle={onChangeChoice(fields.allow_advisors_excluding_positions)}
              />
              <Choice
                {...fields.allow_advisors_copy_risk_tolerance_link}
                title="Copy the fact finder link to use it externally"
                toggle={onChangeChoice(fields.allow_advisors_copy_risk_tolerance_link)}
              />
            </div>
          </div>
        </ConfigurationFormSection>
      </div>

      <h1>Drift Exceptions</h1>
      <div className="content">
        <ConfigurationFormSection onSubmit={onDriftExceptionsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.exceptions_enabled}
                title="Enable drift exceptions"
                toggle={onChangeChoice(fields.exceptions_enabled)}
              />
            </div>
          </div>
          <div
            className={cn({
              hidden: !fields.exceptions_enabled.value
            })}
          >
            <hr />
            <div className="row row-drift-exceptions">
              <div className="col">
                <h4>Monitoring level</h4>
                <div className="radio-container__drift">
                  <div className="radio-wrapper">
                    <Radio
                      checked={fields.drift_monitoring_accounts_enabled.value}
                      onChange={handleDriftMonitoringChange('drift_monitoring_accounts_enabled')}
                    />
                    <span className="input-labe">Accounts</span>
                  </div>
                  <div className="radio-wrapper">
                    <Radio
                      checked={fields.drift_monitoring_investors_enabled.value}
                      onChange={handleDriftMonitoringChange('drift_monitoring_investors_enabled')}
                    />
                    <span className="input-labe">Clients</span>
                  </div>
                  {/* <div className="radio-wrapper">
                    <Radio
                      checked={fields.drift_monitoring_households_enabled.value}
                      onChange={handleDriftMonitoringChange('drift_monitoring_households_enabled')}
                    />
                    <span className="input-labe">Households</span>
                  </div> */}
                </div>
              </div>
            </div>
            <hr />
            <div className="row row-drift-exceptions">
              <div className="col">
                <div>
                  <h4>Emails</h4>
                  <Choice
                    {...fields.exceptions_emails_enabled}
                    title="Enable drift email notifications"
                    toggle={onChangeChoice(fields.exceptions_emails_enabled)}
                  />
                </div>
              </div>
              <div className="col second-column">
                <Choice
                  {...fields.exceptions_summary_emails_enabled}
                  title="Enable drift exceptions summary emails"
                  toggle={onChangeChoice(fields.exceptions_summary_emails_enabled)}
                />
              </div>
            </div>
            <hr />
            <div className="row row-drift-exceptions">
              <div className="col">
                <h4>Thresholds</h4>
                <Choice
                  {...fields.exceptions_for_high_risk}
                  title="Exceptions for high risk"
                  toggle={onChangeChoice(fields.exceptions_for_high_risk)}
                />

                <div className="drift-container__range-slider">
                  {Number.isFinite(fields.drift_threshold_above.initialValue) && (
                    <DriftRangeSlider
                      defaultValue={[fields.drift_threshold_above.initialValue]}
                      min={0}
                      max={9}
                      onChange={onChangeSlider(fields.drift_threshold_above)}
                      label="High risk threshold"
                      steps={0.1}
                      handleStyle={DRIFT_RANGE_SLIDER_STYLE}
                      trackStyle={DRIFT_RANGE_SLIDER_STYLE}
                      onAfter
                    />
                  )}
                </div>

                {fields.drift_monitoring_accounts_enabled.value && (
                  <ThresholdValueInput
                    field={fields.account_value_threshold}
                    onChangeInput={onChangeInput}
                  />
                )}
                {fields.drift_monitoring_investors_enabled.value && (
                  <ThresholdValueInput
                    field={fields.investor_value_threshold}
                    onChangeInput={onChangeInput}
                  />
                )}
                {fields.drift_monitoring_households_enabled.value && (
                  <ThresholdValueInput
                    field={fields.household_value_threshold}
                    onChangeInput={onChangeInput}
                  />
                )}
              </div>
              <div className="col second-column">
                <Choice
                  {...fields.exceptions_for_high_tolerance}
                  title="Exceptions for low risk"
                  toggle={onChangeChoice(fields.exceptions_for_high_tolerance)}
                />

                <div className="drift-container__range-slider">
                  {Number.isFinite(fields.drift_threshold_below.initialValue) && (
                    <DriftRangeSlider
                      defaultValue={[fields.drift_threshold_below.initialValue]}
                      min={0}
                      max={9}
                      onChange={onChangeSlider(fields.drift_threshold_below)}
                      label="Low risk threshold"
                      steps={0.1}
                      handleStyle={DRIFT_RANGE_SLIDER_STYLE}
                      trackStyle={DRIFT_RANGE_SLIDER_STYLE}
                      onAfter
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ConfigurationFormSection>
      </div>

      <h1>Risk Tolerance Questionnaires</h1>
      <div className="content">
        <ConfigurationFormSection onSubmit={onRiskToleranceQuestionnairesSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.show_built_in_questionnaire_templates}
                title="Show built in questionnaire templates"
                toggle={onChangeChoice(fields.show_built_in_questionnaire_templates)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.allow_programmatic_target_score_override}
                title="Override investment objective score set from integration"
                toggle={onChangeChoice(fields.allow_programmatic_target_score_override)}
              />
            </div>
          </div>
        </ConfigurationFormSection>
      </div>

      <h1>Proposals</h1>
      <div className="content">
        <ConfigurationFormSection onSubmit={onProposalsSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_target_management_fee}
                  className="form-control"
                  label="Default target management fee"
                >
                  <NumericFormat {...defaultTargetManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_benchmark_management_fee}
                  className="form-control"
                  label="Default benchmark management fee"
                >
                  <NumericFormat {...defaultBenchmarkManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
            <div className="col">
              <div className="width-inp">
                <VerboseErrorInput
                  {...fields.default_recommended_management_fee}
                  className="form-control"
                  label="Default recommended management fee"
                >
                  <NumericFormat {...defaultRecommendedManagementFeeInput} />
                </VerboseErrorInput>
              </div>
            </div>
          </div>
        </ConfigurationFormSection>
      </div>

      <h1>Email Reminders</h1>
      <div className="content">
        <p className="description__text">
          Automate follow-ups for unanswered email requests sent to investors, such as
          questionnaires, account connections, and document signatures. Reminders are stopped
          automatically once the investor completes the requested action or the email schedule is
          completed.
        </p>
        <ConfigurationFormSection onSubmit={onEmailRemindersSubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.reminders_enabled}
                title="Enable email reminders"
                toggle={onChangeChoice(fields.reminders_enabled)}
              />
              <p />
            </div>
          </div>
          {fields.reminders_enabled.value && <hr />}
          {fields.reminders_enabled.value && (
            <div className="row row-drift-exceptions">
              <div className="col">
                <EmailReminders
                  onSave={handleSaveEmailReminders}
                  initialOffsets={fields.email_reminder_offsets.value}
                />
              </div>
            </div>
          )}
        </ConfigurationFormSection>
      </div>

      <h1 className="checkins-title">
        Check-ins <NewTag />
      </h1>
      <div className="content">
        {fields.checkins_available.value ? (
          <ConfigurationFormSection onSubmit={onCheckinSubmit}>
            <div className="row row-drift-exceptions">
              <div className="col">
                <Choice
                  {...fields.checkins_enabled}
                  title="Enable Check-ins"
                  toggle={onChangeChoice(fields.checkins_enabled)}
                />
              </div>
            </div>
            {fields.checkins_enabled.value && (
              <div>
                <hr />
                <div className="row row-drift-exceptions">
                  <div className="col">
                    <Choice
                      {...fields.checkins_automatic_delivery}
                      title="Send Check-ins Automatically"
                      toggle={onChangeChoice(fields.checkins_automatic_delivery)}
                    />
                    <p className="description__text description__text__automatic_check">
                      Enable to send check-ins emails automatically according to the defined
                      schedule.
                    </p>
                  </div>
                  <div className="col">
                    <div className="checking-frequency__container">
                      <NumericFormat
                        value={fields.checkins_frequency.initialValue}
                        onChange={e => fields.checkins_frequency.onChange(e.target.value)}
                        className="form-control freqeuncy-input"
                        isAllowed={values => {
                          const { floatValue } = values;
                          return floatValue === undefined || (floatValue >= 1 && floatValue <= 99);
                        }}
                        allowNegative={false}
                      />{' '}
                      <span className="input-labe">Check-ins Frequency</span>
                    </div>
                    <p className="description__text">
                      Set how often Check-in emails are sent to clients (in months).
                    </p>
                  </div>
                </div>
              </div>
            )}
          </ConfigurationFormSection>
        ) : (
          <div className="card card-container checkins-card">
            <p className="description__text description__text__automatic_check">
              Understand your clients&apos; feelings about the market and their investments, and
              address their concerns proactively while meeting regulations.
            </p>
            <RequestAction className="btn btn-primary" name="Upgrade plan" />
          </div>
        )}
      </div>

      <h1>Risk Level</h1>
      <p className="description__text description__text__automatic_check">
        Customize your company's risk levels to align with your organizational preferences and
        policies.
      </p>
      <div className="content">
        <ConfigurationFormSection
          onSubmit={onRiskLevelsSubmit}
          onCancel={cancelRiskLevels}
          onResetToDefault={resetRiskLevelsToDefault}
          error={fields.risk_levels.error}
        >
          <RiskLevelsConfiguration riskLevel={fields.risk_levels} handleChange={handleChange} />
        </ConfigurationFormSection>
      </div>

      <h1>Security</h1>
      <div className="content">
        <ConfigurationFormSection onSubmit={onSecuritySubmit}>
          <div className="row row-drift-exceptions">
            <div className="col">
              <Choice
                {...fields.enforce_second_factor}
                title="Enforce Two Factor Authentication"
                toggle={onChangeChoice(fields.enforce_second_factor)}
              />
            </div>
            <div className="col">
              <Choice
                {...fields.mask_account_number}
                title="Mask account number"
                toggle={onChangeChoice(fields.mask_account_number)}
              />
            </div>
          </div>
        </ConfigurationFormSection>
      </div>

      <Disclosure />
    </div>
  );
};

Configuration.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  ...propTypesCheck
};

ThresholdValueInput.propTypes = {
  field: PropTypes.object.isRequired,
  onChangeInput: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'editCompany',
  validate,
  fields: [
    'account_value_threshold',
    'allow_advisors_copy_risk_tolerance_link',
    'allow_advisors_creating_benchmarks',
    'allow_advisors_creating_templates',
    'allow_advisors_custom_securities_access',
    'allow_advisors_excluding_positions',
    'allow_advisors_integrations_access',
    'allow_advisors_models_marketplace_access',
    'allow_advisors_scan_iq_access',
    'allow_advisors_sharing_models',
    'allow_advisors_update_target_score_manually',
    'allow_programmatic_target_score_override',
    'checkins_automatic_delivery',
    'checkins_available',
    'checkins_enabled',
    'checkins_frequency',
    'default_benchmark_management_fee',
    'default_recommended_management_fee',
    'default_target_management_fee',
    'drift_monitoring_accounts_enabled',
    'drift_monitoring_households_enabled',
    'drift_monitoring_investors_enabled',
    'drift_threshold_above',
    'drift_threshold_below',
    'email_reminder_offsets',
    'enforce_second_factor',
    'exceptions_emails_enabled',
    'exceptions_enabled',
    'exceptions_for_high_risk',
    'exceptions_for_high_tolerance',
    'exceptions_summary_emails_enabled',
    'household_value_threshold',
    'investor_value_threshold',
    'mask_account_number',
    'reminders_enabled', // Pca07
    'risk_levels',
    'show_built_in_questionnaire_templates'
  ],
  initialValues: {}
})(BackendValidation(Configuration));

import { Text } from '@react-pdf/renderer';
import UpsideDownsideChart, { THEME } from 'components/charts/upside-downside-chart/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import styles from './styles';

const ProposalUpDownCaptureComparison = ({ breakSection, metadata, proposal }) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);
  const { body, title } = metadata;

  const market = {
    up: company.market_upside_performance,
    down: company.market_downside_performance,
    name: proposal.benchmark_label
  };
  const target = {
    upside: proposal.target.prism_score_summary.upside_capture_ratio / 10,
    downside: proposal.target.prism_score_summary.downside_capture_ratio / 10,
    name: proposal.target_label
  };
  const recommended = proposal.recommended
    ? {
        upside: proposal.recommended.prism_score_summary.upside_capture_ratio / 10,
        downside: proposal.recommended.prism_score_summary.downside_capture_ratio / 10,
        name: proposal.recommended_label
      }
    : null;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <Text style={styles.text}>
        If the market was down {market.down}%, 90% of the time
        <Text style={styles.bold}>{target.name}</Text> performance may be -
        {Math.round(target.downside * market.down * 10)}%
        {recommended && (
          <>
            {' '}
            and <Text style={styles.bold}>{recommended.name}</Text> performance may be -
            {Math.round(recommended.downside * market.down * 10)}%
          </>
        )}
      </Text>
      <Text style={styles.text}>
        If the market was up {market.up}%, 90% of the time{' '}
        <Text style={styles.bold}>{target.name}</Text> performance may be +
        {Math.round(target.upside * market.up * 10)}%
        {recommended && (
          <>
            {' '}
            and <Text style={styles.bold}>{recommended.name}</Text> performance may be +
            {Math.round(recommended.upside * market.up * 10)}%
          </>
        )}
      </Text>
      <Text style={styles.separator}>Next 12 Months</Text>
      <UpsideDownsideChart
        target={target}
        targetName={proposal.target_label}
        recommended={recommended}
        recommendedName={proposal.recommended_label}
        theme={THEME.proposal}
        withLegend
      />
    </BaseSection>
  );
};

ProposalUpDownCaptureComparison.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

ProposalUpDownCaptureComparison.defaultProps = {
  breakSection: false
};

export default ProposalUpDownCaptureComparison;

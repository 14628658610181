import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import './styles.scss';

import {
  PRIMARY_MEMBER,
  TEAM_MEMBERS,
  TEAM_DESCRIPTION,
  BUTTON_TEXT,
  NO_DESCRIPTION,
  SUPERVISOR_MEMBER,
  EMPTY_MEMBERS_MESSAGE
} from '../../constants';

const TableExpander = ({ original, callbackEdit, editEnabled }) => {
  const { team_memberships: members, description } = original;
  return (
    <div className="team-expander">
      <div className="team-expander__header">
        <div>
          <span>
            <b>{TEAM_DESCRIPTION}</b>
            <br /> {description || NO_DESCRIPTION}
          </span>
        </div>
      </div>
      {!isEmpty(members) && <br />}
      <div className="team-expander__content">
        {!isEmpty(members) ? (
          <div className="member">
            <span className="member__title">
              <b>{TEAM_MEMBERS}</b>
            </span>
            <br />
            {members.map(member => (
              <div className="member__body">
                <span>
                  {member?.advisor?.user?.first_name} {member?.advisor?.user?.last_name} (
                  {member?.advisor?.user?.email})
                </span>
                {member?.is_primary && <span className="member__primary">{PRIMARY_MEMBER}</span>}
                {member?.is_supervisor && (
                  <span className="member__supervisor">{SUPERVISOR_MEMBER}</span>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="empty">
            <p>{EMPTY_MEMBERS_MESSAGE}</p>
            {editEnabled && (
              <button
                type="button"
                className="btn btn-link mb-5"
                onClick={() => callbackEdit({ team: original, show: true })}
              >
                <span className="icon-plus" />
                {BUTTON_TEXT}
              </button>
            )}
          </div>
        )}
      </div>
      {!isEmpty(members) && editEnabled && (
        <div className="team-expander__footer">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => callbackEdit({ team: original, show: true })}
          >
            <span className="icon-plus" />
            {BUTTON_TEXT}
          </button>
        </div>
      )}
    </div>
  );
};

TableExpander.defaultProps = {
  callbackEdit: () => {},
  index: 0,
  original: []
};

TableExpander.propTypes = {
  callbackEdit: PropTypes.func,
  editEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number,
  original: PropTypes.arrayOf(PropTypes.object)
};

export default TableExpander;

import {
  teamColumnMember,
  teamColumnPrimary,
  teamColumnRemove,
  teamColumnSupervisor
} from './custom';

export const columns = [
  {
    id: 'member',
    Header: 'Member',
    Cell: teamColumnMember,
    accessor: member => member?.advisor?.user?.first_name,
    headerStyle: { justifyContent: 'flex-start' },
    style: { justifyContent: 'flex-start', padding: '10px 10px' }
  },
  {
    width: 150,
    id: 'supervisor',
    Header: 'Supervisor',
    accessor: member => member?.is_supervisor,
    Cell: teamColumnSupervisor
  },
  {
    width: 150,
    id: 'primary',
    Header: 'Primary',
    accessor: member => member?.is_primary,
    Cell: teamColumnPrimary
  },
  {
    width: 100,
    id: 'remove',
    Header: 'Remove',
    Cell: teamColumnRemove
  }
];

export default columns;

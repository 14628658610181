// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-security-list .custom-security-list__header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 10px;
}
.custom-security-list .custom-security-list__header h2 {
  font-size: 2rem;
  line-height: normal;
  margin-bottom: 0;
}
.custom-security-list .btn-container__header {
  display: flex;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/containers/security/list/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;AAFJ;AAII;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAFN;AAKE;EACE,aAAA;EACA,SAAA;AAHJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.custom-security-list {\n  .custom-security-list__header {\n    display: flex;\n    justify-content: space-between;\n    padding-top: 20px;\n    margin-bottom: 10px;\n\n    h2 {\n      font-size: 2rem;\n      line-height: normal;\n      margin-bottom: 0;\n    }\n  }\n  .btn-container__header {\n    display: flex;\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

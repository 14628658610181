/* global MONTH_YEAR_FORMAT */
import moment from 'moment';
import { formatPercentage } from 'utils/utils';

export const TARGET_ANALYTICS = 'target_analytics';
export const RECOMMENDED_ANALYTICS = 'recommended_analytics';
export const BENCHMARK_ANALYTICS = 'benchmark_analytics';

export const getHistoricalReturnsHeaders = (start, title) => [
  title,
  '1 Yr',
  '3 Yr',
  '5 Yr',
  `Since ${moment(start).format(MONTH_YEAR_FORMAT)}`
];

const getYearsThreshold = startString => {
  const today = new Date();

  // Calculate the date thresholds
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(today.getFullYear() - 3);

  const fiveYearsAgo = new Date();
  fiveYearsAgo.setFullYear(today.getFullYear() - 5);

  const start = new Date(startString);
  // Check if the date is older than 1 year, 3 years, or 5 years
  const isOlderThan1Year = start < oneYearAgo;
  const isOlderThan3Years = start < threeYearsAgo;
  const isOlderThan5Years = start < fiveYearsAgo;

  return [isOlderThan1Year, isOlderThan3Years, isOlderThan5Years];
};

export const getCumulativeReturnsFromSource = (proposal, source) => {
  const [isOlderThan1Year, isOlderThan3Years, isOlderThan5Years] = getYearsThreshold(
    proposal.start
  );

  return [
    isOlderThan1Year ? formatPercentage(proposal[source].summary.one_year_cumulative_return) : '-',
    isOlderThan3Years
      ? formatPercentage(proposal[source].summary.three_year_cumulative_return)
      : '-',
    isOlderThan5Years
      ? formatPercentage(proposal[source].summary.five_year_cumulative_return)
      : '-',
    formatPercentage(proposal[source].summary.cumulative_return)
  ];
};

export const getAnnualizedReturnsFromSource = (proposal, source) => {
  const [isOlderThan1Year, isOlderThan3Years, isOlderThan5Years] = getYearsThreshold(
    proposal.start
  );

  return [
    isOlderThan1Year ? formatPercentage(proposal[source].summary.one_year_annualized_return) : '-',
    isOlderThan3Years
      ? formatPercentage(proposal[source].summary.three_year_annualized_return)
      : '-',
    isOlderThan5Years
      ? formatPercentage(proposal[source].summary.five_year_annualized_return)
      : '-',
    formatPercentage(proposal[source].summary.annualized_return)
  ];
};

import { ADVISOR_UPDATE, CONNECT_INVITATION_SENT } from 'constants/actions';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_COMPANY_LOGO,
  AUTH_SET_USER,
  SET_ADVISOR_BY_MANAGER,
  SET_SUPERVISOR_BY_MANAGER,
  USER_LIST,
  USER_UPDATE
} from 'constants/actions/auth';

export const initialState = {
  advisorByManager: 0,
  supervisorByManager: 0 // 0 means company-level or "All advisors"
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        token: action.data
      };

    case AUTH_LOGOUT:
      return { ...initialState };

    case AUTH_SET_USER:
    case USER_UPDATE:
      return {
        ...state,
        user: { ...action.data }
      };

    case AUTH_SET_COMPANY_LOGO:
      return {
        ...state,
        user: {
          ...state.user,
          advisor: {
            ...state.user.advisor,
            company: {
              ...state.user.advisor.company,
              logo: action.data.file
            }
          }
        }
      };

    case ADVISOR_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          advisor: {
            ...state.user.advisor,
            ...action.data
          }
        }
      };

    case USER_LIST:
      return { ...state, users: [...action.data] };

    case SET_ADVISOR_BY_MANAGER:
      return {
        ...state,
        advisorByManager: action.data
      };

    case SET_SUPERVISOR_BY_MANAGER: {
      return {
        ...state,
        supervisorByManager: action.data
      };
    }
    // optimistically handle the following actions updating the stats locally
    // according to the action expected effect:
    // - CONNECT_INVITATION_SENT
    case CONNECT_INVITATION_SENT:
      if (!state.user.advisor.company.available_connections) return { ...state };
      return {
        ...state,
        user: {
          ...state.user,
          advisor: {
            ...state.user.advisor,
            company: {
              ...state.user.advisor.company,
              available_connections: state.user.advisor.company.available_connections - 1
            }
          }
        }
      };

    default:
      return state;
  }
}

import { Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import Row from 'reports/base/table';
import { LANDSCAPE_ORIENTATION } from 'reports/constants';
import { formatMoney } from 'utils/utils';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import { HISTORICAL_RETURNS_CHART_ID } from '../constants';
import styles from './styles';

const ProposalPerformance = ({ breakSection, metadata, orientation, proposal }) => {
  const hasBenchmark = !!proposal.benchmark;
  const hasRecommended = !!proposal.recommended;
  const { body, title } = metadata;

  const header = ['Starting Value', proposal.target_label || 'Current Portfolio'];
  const content = [
    formatMoney(proposal.starting_value),
    formatMoney(proposal.target_analytics.summary.cumulative_value)
  ];

  if (hasRecommended) {
    header.push(proposal.recommended_label);
    content.push(formatMoney(proposal.recommended_analytics.summary.cumulative_value));
  }

  if (hasBenchmark) {
    header.push(proposal.benchmark_label);
    content.push(formatMoney(proposal.benchmark_analytics.summary.cumulative_value));
  }

  /**
   * When there's no custom content (body) the section will not be broken internally
   * since it can be rendered on a page regardless of its orientation.
   *
   * When there's custom content (body) or the page orientation is landscape,
   * the section will always be rendered on its own page.
   */
  return (
    <BaseSection
      body={body}
      breakSection={breakSection || !!body || orientation === LANDSCAPE_ORIENTATION}
      title={title}
      wrap={!!body}
    >
      <ExtraDataSection proposal={proposal} withManagementFee />
      <Row elements={header} cellStyle={styles.headerCell} />
      <Row elements={content} />
      <Image src={proposal[HISTORICAL_RETURNS_CHART_ID]} style={styles.chart} />
    </BaseSection>
  );
};

ProposalPerformance.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  orientation: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalPerformance.defaultProps = {
  breakSection: false
};

export default ProposalPerformance;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#CheckInsReviewWidget .card-content {
  padding: 30px;
}
#CheckInsReviewWidget .card-content .card-content__summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  height: 100%;
  grid-gap: 15px;
  gap: 15px;
}
#CheckInsReviewWidget .card-content .card-content__summary .summary__box {
  display: flex;
  align-items: center;
  gap: 15px;
}
#CheckInsReviewWidget .card-content .card-content__summary .summary__box .box__image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bcbfc4;
  background-color: #f9f9f9;
  border-radius: 5px;
  height: 85px;
  width: 85px;
}
#CheckInsReviewWidget .card-content .card-content__summary .summary__box .box__content > span {
  font-weight: 500;
  font-size: 1.75rem;
}
#CheckInsReviewWidget .card-content .card-content__summary .summary__box .box__content > p {
  margin-bottom: 0;
  font-size: 0.8rem;
}
#CheckInsReviewWidget .card-content .card-content__summary .summary__box .box__content > a {
  font-weight: 400;
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/dashboard/widgets/check-ins-review-widget/styles.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AAFJ;AAII;EACE,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,cAAA;EAAA,SAAA;AAFN;AAIM;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFR;AAIQ;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAFV;AAMU;EACE,gBAAA;EACA,kBAAA;AAJZ;AAOU;EACE,gBAAA;EACA,iBAAA;AALZ;AAQU;EACE,gBAAA;EACA,iBAAA;AANZ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#CheckInsReviewWidget {\n  .card-content {\n    padding: 30px;\n\n    .card-content__summary {\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n      grid-template-rows: repeat(2, 1fr);\n      align-items: center;\n      justify-content: center;\n      height: 100%;\n      gap: 15px;\n\n      .summary__box {\n        display: flex;\n        align-items: center;\n        gap: 15px;\n\n        .box__image {\n          display: flex;\n          align-items: center;\n          justify-content: center;\n          border: 1px solid $gray-light-2;\n          background-color: #f9f9f9;\n          border-radius: 5px;\n          height: 85px;\n          width: 85px;\n        }\n\n        .box__content {\n          & > span {\n            font-weight: 500;\n            font-size: 1.75rem;\n          }\n\n          & > p {\n            margin-bottom: 0;\n            font-size: 0.8rem;\n          }\n\n          & > a {\n            font-weight: 400;\n            font-size: 0.8rem;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

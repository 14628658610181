/* eslint-disable class-methods-use-this, import/no-cycle, react/no-did-update-set-state, react/no-unused-state */
import cn from 'classnames';
import { investorSelector } from 'components/advisor/investors/selectors';
import TargetScoreWizard from 'components/advisor/target-score-wizard';
import WelcomeModal from 'components/advisor/welcome-modal';
import SubscriptionTrialBanner from 'components/subscription-banner/trial';
import HeaderAdvisor from 'containers/layout/header-advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AccountProvider from 'providers/account';
import ActionProvider from 'providers/actions';
import AdvisorProvider from 'providers/advisor';
import AIAssistantProvider from 'providers/ai-assistant';
import AttachmentsProvider from 'providers/attachments';
import AuthProvider from 'providers/auth';
import CompanyProvider from 'providers/company';
import CustomSecurityProvider from 'providers/custom-security';
import HouseholdProvider from 'providers/household';
import IntegrationProvider from 'providers/integrations';
import InvestorProvider from 'providers/investor';
import InvestorsGoalsProvider from 'providers/investor-goals';
import InvitationProvider from 'providers/invitation';
import IPSProvider from 'providers/ips';
import MarketProvider from 'providers/market';
import ModelProvider from 'providers/model';
import NotificationsProvider from 'providers/notifications';
import PortfolioProvider from 'providers/portfolio';
import PrismProvider from 'providers/prism';
import ProposalProvider from 'providers/proposal';
import ProspectProvider from 'providers/prospects';
import QuestionProvider from 'providers/question';
import ErrorsProvider from 'providers/server-errors-provider';
import StaffCompaniesProvider from 'providers/staff-companies';
import SubscriptionProvider from 'providers/subscription';
import SurveillanceExceptionsProvider from 'providers/surveillance-exceptions';
import TeamProvider from 'providers/team';
import TemplateProvider from 'providers/templates';
import UserProvider from 'providers/user';
import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { filteredNotificationsSelector } from 'selectors/notifications';
import RiskToleranceQuestionnaireEmptyReportViewer from '../risk-tolerance-questionnaire/report/empty/viewer';

const WELCOMING_NOTIFICATION_VERB = 'welcoming';

export const AdvisorContext = createContext({});

export class Advisor extends Component {
  constructor(props) {
    super(props);
    this.printRiskToleranceQuestionnaire = this.printRiskToleranceQuestionnaire.bind(this);
    this.setupTargetScoreWizard = this.setupTargetScoreWizard.bind(this);
    this.showTargetScoreWizard = this.showTargetScoreWizard.bind(this);
    this.hideTargetScoreWizard = this.hideTargetScoreWizard.bind(this);
    this.onWelcomeAccepted = this.onWelcomeAccepted.bind(this);
    this.getClientProvider = this.getClientProvider.bind(this);

    const isProspectSection = this.isProspectSection(props.location.pathname);
    const clientProvider = this.getClientProvider(props.user, props.location.pathname);

    this.state = {
      context: {
        accountProvider: props.accountProvider,
        actionProvider: props.actionProvider,
        advisorByManager: props.advisorByManager,
        advisorProvider: props.advisorProvider,
        aiAssistantProvider: props.aiAssistantProvider,
        attachmentsProvider: props.attachmentsProvider,
        authProvider: props.authProvider,
        clientProvider,
        companyProvider: props.companyProvider,
        customSecurityProvider: props.customSecurityProvider,
        errorsProvider: props.errorsProvider,
        householdProvider: props.householdProvider,
        integrationProvider: props.integrationProvider,
        investorGoalsProvider: props.investorGoalsProvider,
        investorProvider: props.investorProvider,
        invitationProvider: props.invitationProvider,
        ipsProvider: props.ipsProvider,
        isProspectSection,
        location: props.location,
        marketProvider: props.marketProvider,
        modelProvider: props.modelProvider,
        notificationsProvider: props.notificationsProvider,
        params: props.params,
        portfolioProvider: props.portfolioProvider,
        printRiskToleranceQuestionnaire: this.printRiskToleranceQuestionnaire,
        prismProvider: props.prismProvider,
        proposalProvider: props.proposalProvider,
        prospectProvider: props.prospectProvider,
        questionProvider: props.questionProvider,
        routerActions: props.routerActions,
        setupTargetScoreWizard: this.setupTargetScoreWizard,
        showTargetScoreWizard: this.showTargetScoreWizard,
        staffCompaniesProvider: props.staffCompaniesProvider,
        subscriptionProvider: props.subscriptionProvider,
        supervisorByManager: props.supervisorByManager,
        surveillanceExceptionsProvider: props.surveillanceExceptionsProvider,
        taxonomies: props.taxonomies,
        teamProvider: props.teamProvider,
        templateProvider: props.templateProvider,
        user: props.user,
        userProvider: props.userProvider
      },
      welcoming: null,
      targetScoreWizard: {
        view: { investors: [], refresh: null, show: false, targetAccountIds: null },
        setup: null
      },
      riskToleranceQuestionnaire: { show: false, questionnaireTemplateId: undefined }
    };
  }

  getChildContext() {
    const { context } = this.state;
    return context;
  }

  componentDidMount() {
    const { companyProvider, marketProvider, notificationsProvider } = this.props;
    notificationsProvider.list({ verb: WELCOMING_NOTIFICATION_VERB });
    companyProvider.getLimits();
    marketProvider.getTaxonomies();
  }

  componentDidUpdate(prevProps) {
    const { advisorByManager, supervisorByManager, user, location, notifications } = this.props;
    const {
      advisorByManager: prevAdvisorByManager,
      location: prevLocation,
      notifications: prevNotifications,
      supervisorByManager: prevSupervisorByManager,
      user: prevUser
    } = prevProps;
    const { context, welcoming } = this.state;

    let clientProvider;
    if (!_.isEqual(user, prevUser)) {
      clientProvider = this.getClientProvider(user, location.pathname);
      this.setState({ context: { ...context, clientProvider, user } });
    }

    if (advisorByManager !== prevAdvisorByManager)
      this.setState({ context: { ...context, advisorByManager } });

    if (supervisorByManager !== prevSupervisorByManager)
      this.setState({ context: { ...context, supervisorByManager } });

    if (location.pathname !== prevLocation.pathname) {
      const isProspectSection = this.isProspectSection(location.pathname);
      clientProvider = this.getClientProvider(user, location.pathname);
      this.setState({ context: { ...context, isProspectSection, clientProvider, location } });
    }

    if (notifications.length !== prevNotifications.length)
      notifications.forEach(notification => {
        if (!welcoming) this.setState({ welcoming: notification.id });
      });
  }

  isProspectSection = pathname => pathname.includes('advisor/prospects');

  getClientProvider = (user, pathname) => {
    const { investorProvider, prospectProvider } = this.props;
    const isProspectUser = user && user.investor && user.investor.is_prospect;
    const isProspectSection = this.isProspectSection(pathname);
    return isProspectUser || isProspectSection ? prospectProvider : investorProvider;
  };

  printRiskToleranceQuestionnaire(questionnaireTemplateId) {
    // Set the questionnaire as printing and in the next loop set it to false.
    // This will trigger the print action in the RiskToleranceQuestionnairePDF component
    this.setState({ riskToleranceQuestionnaire: { show: true, questionnaireTemplateId } });
  }

  setupTargetScoreWizard(wizard) {
    /**
     * Stores a default setup to be used in successive calls of showTargetScoreWizard
     */
    const { targetScoreWizard } = this.state;
    this.setState({ targetScoreWizard: { ...targetScoreWizard, setup: { ...wizard } } });
  }

  showTargetScoreWizard(wizard) {
    /**
     * Show the targeScoreWizard with the given params and the ones saved in the setup (if any)
     */
    const { targetScoreWizard } = this.state;
    this.setState({
      targetScoreWizard: {
        ...targetScoreWizard,
        view: { ...targetScoreWizard.setup, ...wizard, show: true }
      }
    });
  }

  hideTargetScoreWizard() {
    /**
     * Hide the targeScoreWizard and reset the params (except the ones saved in the setup)
     */
    const { targetScoreWizard } = this.state;
    this.setState({
      targetScoreWizard: {
        ...targetScoreWizard,
        view: { show: false, investors: [], refresh: null, targetAccountIds: null }
      }
    });
  }

  onWelcomeAccepted() {
    const { welcoming } = this.state;
    const { notificationsProvider } = this.props;

    if (welcoming) {
      notificationsProvider.read([welcoming]);
      this.setState({ welcoming: null });
    }
  }

  render() {
    const { children, investor, user } = this.props;
    const { context, riskToleranceQuestionnaire, targetScoreWizard, welcoming } = this.state;
    const { userProvider } = context;

    let banner = null;
    // kept in case it's needed again
    // if (userProvider.isEntry(user))
    //   banner = <SubscriptionUpgradeBanner plan={userProvider.getProduct(user)} />;
    if (userProvider.isTrialing(user))
      banner = <SubscriptionTrialBanner trialEnd={user.advisor.company.trial_end} />;

    return (
      <AdvisorContext.Provider value={context}>
        <div className={cn('advisor-container', { 'with-banner': !!banner })}>
          {banner}
          <HeaderAdvisor />
          {children}
          <RiskToleranceQuestionnaireEmptyReportViewer
            investor={investor}
            onHidden={() => this.setState({ riskToleranceQuestionnaire: { show: false } })}
            questionnaireTemplateId={riskToleranceQuestionnaire.questionnaireTemplateId}
            showModal={riskToleranceQuestionnaire.show}
          />
          <TargetScoreWizard {...targetScoreWizard.view} onHide={this.hideTargetScoreWizard} />
          {welcoming && <WelcomeModal onWelcomeAccepted={this.onWelcomeAccepted} />}
        </div>
      </AdvisorContext.Provider>
    );
  }
}

const AdvisorContextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  advisorByManager: PropTypes.number,
  advisorProvider: PropTypes.object.isRequired,
  aiAssistantProvider: PropTypes.object.isRequired,
  attachmentsProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  companyProvider: PropTypes.object.isRequired,
  customSecurityProvider: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  integrationProvider: PropTypes.object.isRequired,
  investorGoalsProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  invitationProvider: PropTypes.object.isRequired,
  ipsProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  notificationsProvider: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  portfolioProvider: PropTypes.object.isRequired,
  prismProvider: PropTypes.object.isRequired,
  proposalProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  questionProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  staffCompaniesProvider: PropTypes.object.isRequired,
  subscriptionProvider: PropTypes.object.isRequired,
  supervisorByManager: PropTypes.number,
  surveillanceExceptionsProvider: PropTypes.object.isRequired,
  taxonomies: PropTypes.array,
  teamProvider: PropTypes.object.isRequired,
  templateProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

Advisor.propTypes = { ...AdvisorContextTypes };

Advisor.defaultProps = { taxonomies: [] };

Advisor.childContextTypes = {
  ...AdvisorContextTypes,
  clientProvider: PropTypes.object.isRequired,
  isProspectSection: PropTypes.bool.isRequired,
  printRiskToleranceQuestionnaire: PropTypes.func.isRequired,
  setupTargetScoreWizard: PropTypes.func.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired
};

export default connect(
  state => ({
    advisorByManager: state.auth.advisorByManager,
    investor: investorSelector(state),
    notifications: filteredNotificationsSelector(state, WELCOMING_NOTIFICATION_VERB),
    supervisorByManager: state.auth.supervisorByManager,
    taxonomies: state.market.taxonomies,
    user: state.auth.user
  }),
  dispatch => ({
    accountProvider: new AccountProvider({ dispatch }),
    actionProvider: new ActionProvider({ dispatch }),
    advisorProvider: new AdvisorProvider({ dispatch }),
    aiAssistantProvider: new AIAssistantProvider({ dispatch }),
    attachmentsProvider: new AttachmentsProvider({ dispatch }),
    authProvider: new AuthProvider({ dispatch }),
    companyProvider: new CompanyProvider({ dispatch }),
    customSecurityProvider: new CustomSecurityProvider({ dispatch }),
    errorsProvider: new ErrorsProvider({ dispatch }),
    householdProvider: new HouseholdProvider({ dispatch }),
    integrationProvider: new IntegrationProvider({ dispatch }),
    investorGoalsProvider: new InvestorsGoalsProvider({ dispatch }),
    investorProvider: new InvestorProvider({ dispatch }),
    invitationProvider: new InvitationProvider({ dispatch }),
    ipsProvider: new IPSProvider({ dispatch }),
    marketProvider: new MarketProvider({ dispatch }),
    modelProvider: new ModelProvider({ dispatch }),
    notificationsProvider: new NotificationsProvider({ dispatch }),
    portfolioProvider: new PortfolioProvider({ dispatch }),
    prismProvider: new PrismProvider({ dispatch }),
    proposalProvider: new ProposalProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    questionProvider: new QuestionProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch),
    staffCompaniesProvider: new StaffCompaniesProvider({ dispatch }),
    subscriptionProvider: new SubscriptionProvider({ dispatch }),
    surveillanceExceptionsProvider: new SurveillanceExceptionsProvider({ dispatch }),
    teamProvider: new TeamProvider({ dispatch }),
    templateProvider: new TemplateProvider({ dispatch }),
    userProvider: new UserProvider({ dispatch })
  })
)(Advisor);

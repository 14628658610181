// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration--risk-level__slider {
  width: 50px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  min-height: 500px;
}
.configuration--risk-level__slider .rc-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.configuration--risk-level__slider .rc-slider-dot {
  display: none;
}
.configuration--risk-level__slider .rc-slider-rail {
  width: 10px;
  border-radius: 5px;
}
.configuration--risk-level__slider .rc-slider-track {
  background: transparent;
}
.configuration--risk-level__slider .rc-slider-handle {
  height: 20px;
  width: 20px;
  margin-left: -5px;
  margin-top: 1px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.configuration--risk-level__slider .rc-slider-handle:hover {
  border-color: #09acf8 !important;
}
.configuration--risk-level__slider .rc-slider-handle .rc-slider-handle-draggin {
  box-shadow: 0 0 5px rgba(16, 156, 243, 0.3) !important;
  border-color: #09acf8 !important;
}
.configuration--risk-level__slider .rc-slider-mark-text {
  color: #000;
  font-size: 10px;
  margin-left: -25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/risk-levels/configuration/slider/styles.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AAFF;AAIE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,WAAA;EACA,kBAAA;AAJJ;AAOE;EACE,uBAAA;AALJ;AAQE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,sCAAA;AANJ;AAQI;EACE,gCAAA;AANN;AASI;EACE,sDAAA;EACA,gCAAA;AAPN;AAWE;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AATJ","sourcesContent":["@import 'assets/scss/commons/app-mixin';\n@import 'assets/scss/commons/variables';\n\n.configuration--risk-level__slider {\n  width: 50px;\n  height: 100%;\n  position: relative;\n  margin: 0 auto;\n  min-height: 500px;\n\n  .rc-slider {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n\n  .rc-slider-dot {\n    display: none;\n  }\n\n  .rc-slider-rail {\n    width: 10px;\n    border-radius: 5px;\n  }\n\n  .rc-slider-track {\n    background: transparent;\n  }\n\n  .rc-slider-handle {\n    height: 20px;\n    width: 20px;\n    margin-left: -5px;\n    margin-top: 1px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n\n    &:hover {\n      border-color: $primary !important;\n    }\n\n    .rc-slider-handle-draggin {\n      box-shadow: 0 0 5px rgba(16, 156, 243, 0.3) !important;\n      border-color: $primary !important;\n    }\n  }\n\n  .rc-slider-mark-text {\n    color: #000;\n    font-size: 10px;\n    margin-left: -25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#check-ins-history {
  margin-top: 10px;
}
#check-ins-history .history-grid {
  display: flex;
  justify-content: center;
  gap: 5px;
}
#check-ins-history .history-grid .history-grid__column {
  display: flex;
  flex-direction: column;
  flex-basis: 130px;
  gap: 10px;
  /* first column with the topics */
  /* first column with check-in results */
}
#check-ins-history .history-grid .history-grid__column .history-grid__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85px;
}
#check-ins-history .history-grid .history-grid__column .history-grid__cell img {
  height: 40px;
}
#check-ins-history .history-grid .history-grid__column .history-grid__cell:first-child {
  font-size: 0.85rem;
  min-height: 36px;
}
#check-ins-history .history-grid .history-grid__column:first-child {
  flex-basis: 150px;
  font-size: 0.85rem;
}
#check-ins-history .history-grid .history-grid__column:first-child .history-grid__cell {
  justify-content: flex-end;
  padding-right: 15px;
  font-weight: 500;
}
#check-ins-history .history-grid .history-grid__column:nth-child(2) .history-grid__cell:first-child {
  font-weight: bold;
}
#check-ins-history .check-ins-history__view-all {
  margin-top: 2rem;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/check-ins-history/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAGE;EACE,aAAA;EACA,uBAAA;EACA,QAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,SAAA;EAkBA,iCAAA;EAYA,uCAAA;AA7BN;AACM;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACR;AACQ;EACE,YAAA;AACV;AAEQ;EACE,kBAAA;EACA,gBAAA;AAAV;AAKM;EACE,iBAAA;EACA,kBAAA;AAHR;AAKQ;EACE,yBAAA;EACA,mBAAA;EACA,gBAAA;AAHV;AAUU;EACE,iBAAA;AARZ;AAeE;EACE,gBAAA;EACA,kBAAA;AAbJ","sourcesContent":["@import 'assets/scss/commons/variables';\n\n#check-ins-history {\n  margin-top: 10px;\n\n  .history-grid {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n\n    .history-grid__column {\n      display: flex;\n      flex-direction: column;\n      flex-basis: 130px;\n      gap: 10px;\n\n      .history-grid__cell {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        min-height: 85px;\n\n        img {\n          height: 40px;\n        }\n\n        &:first-child {\n          font-size: 0.85rem;\n          min-height: 36px;\n        }\n      }\n\n      /* first column with the topics */\n      &:first-child {\n        flex-basis: 150px;\n        font-size: 0.85rem;\n\n        .history-grid__cell {\n          justify-content: flex-end;\n          padding-right: 15px;\n          font-weight: 500;\n        }\n      }\n\n      /* first column with check-in results */\n      &:nth-child(2) {\n        .history-grid__cell {\n          &:first-child {\n            font-weight: bold;\n          }\n        }\n      }\n    }\n  }\n\n  .check-ins-history__view-all {\n    margin-top: 2rem;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import Choice from 'app/components/form/choice';
import './styles.scss';

import { REMOVE_MEMBER_ICON } from '../../constants';

export const teamColumnMember = data => {
  const { original } = data;
  const displayName =
    original?.advisor?.user?.name ||
    `${original?.advisor?.user?.first_name} ${original?.advisor?.user?.last_name}`;
  return (
    <span>
      {displayName} {original?.advisor?.user?.email && `(${original?.advisor?.user?.email})`}
    </span>
  );
};

export const teamColumnPrimary = ({ tdProps, original }) => {
  const { rest } = tdProps;
  return <Choice checked={original.is_primary} className="choice" {...rest} />;
};

export const teamColumnSupervisor = ({ tdProps, original }) => {
  const { rest } = tdProps;
  return <Choice checked={original.is_supervisor} className="choice" {...rest} />;
};

export const teamColumnRemove = ({ tdProps }) => {
  const { rest } = tdProps;
  return (
    <button type="button" className="btn btn-remove-team" {...rest}>
      <span className="icon-minus" />
      {REMOVE_MEMBER_ICON}
    </button>
  );
};
